import React, { Suspense } from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../components/layout/smoothScroll";
import TopParallax from "../components/portfolio/blocks/topSection/topParallax";

import Layout from '../components/layout/layout'
import MenuGlobal from '../components/menu'

import '../sass/portfolio.scss';
import SmallHeightMarginWidth from "../components/portfolio/blocks/galleries/smallHeightMarginWidth";

const PortfolioIndividualTemplate = ({ pageContext, location }) => {

  console.log(pageContext)

  const AreasTitleText = React.lazy(() => import("../components/portfolio/blocks/texts/areasTitleText"));
  const SliderWithBackgroundImageWithColor = React.lazy(() => import("../components/portfolio/blocks/sliders/sliderWithBackgroundImageWithColor"));
  const FullWidthImageZoom = React.lazy(() => import("../components/portfolio/blocks/fullWidthImage/fullWidthImageZoom"));
  const SameHeightTitleText = React.lazy(() => import("../components/portfolio/blocks/texts/sameHeightTitleText"));
  const TwoImagesDifferentsSpeeds = React.lazy(() => import("../components/portfolio/blocks/galleries/twoImagesDifferentsSpeeds"));
  const TwoImagesDifferentsSpeedsMobile = React.lazy(() => import("../components/portfolio/blocks/galleries/twoImagesDifferentsSpeedsMobile"));
  const SmallHeightfullWidth = React.lazy(() => import("../components/portfolio/blocks/galleries/smallHeightfullWidth"));
  const SliderCardsHorizontalWithDrag = React.lazy(() => import("../components/portfolio/blocks/sliders/sliderCardsHorizontalWithDrag"));
  const TextLeftSliderRightWithOneAndHalfSlides = React.lazy(() => import("../components/portfolio/blocks/sliders/textLeftSliderRightWithOneAndHalfSlides"));
  const TextLeftSliderRightWithOneAndHalfSlidesMobile = React.lazy(() => import("../components/portfolio/blocks/sliders/textLeftSliderRightWithOneAndHalfSlidesMobile"));
  const VideoInViewPlayAndPauseWithBorderRadius = React.lazy(() => import("../components/portfolio/blocks/videos/videoInViewPlayAndPauseWithBorderRadius"));
  const FullWidthImage = React.lazy(() => import("../components/portfolio/blocks/fullWidthImage/fullWidthImage"));
  const ImageLinkYoutube = React.lazy(() => import("../components/portfolio/blocks/videos/imageLinkYoutube"));
  const MarginWidthMockupWebsiteWithButton = React.lazy(() => import("../components/portfolio/blocks/marginWidthImage/marginWidthMockupWebsiteWithButton"));


  const { t } = useTranslation()
  const breakpoints = useBreakpoint();

  const mobile = breakpoints.mobile

  var seoGeneral = t("portfolio", { returnObjects: true }).seo
  var seo = {
    title: pageContext.project.projetos.seo?.title || seoGeneral.title,
    description: pageContext.project.projetos.seo?.description || seoGeneral.description,
    image: pageContext.project.featuredImage.node.sourceUrl || seoGeneral.image
  }


  // pageContext.project.projetos.blocks = [
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 8,
  //     "itens": [
  //       {
  //         "link": null,
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/Group-1-2-e1736792197728.png",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/Group-1-2-e1736792197728.png",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       },
  //       {
  //         "link": null,
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/Grupo-316-e1736792203454.png",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/Grupo-316-e1736792203454.png",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 9,
  //     "itens": null,
  //     "cols": [
  //       {
  //         "text": "Fotografia<br/>Vídeo<br/>Editorial<br/>Conteúdo Redes Sociais<br/>Website",
  //         "titleandtext": {
  //           "text": "Fundada em 1930 com o nome “Joaquim Ramalho & Filhos” a Ramalhos é, atualmente, um dos maiores fabricantes do mundo de fornos industriais. <br/>Numa representação do melhor que a marca tem, a fotografia revela em detalhe a silhueta de cada um dos fornos Ramalhos. <br/>Gama a gama, os produtos estrela da marca apresentam-se ao público nas suas mais diversas formas, através de uma produção pensada ao pormenor, desde a luz à edição.",
  //           "title": "Ramalhos: Pure Baking Pleasure"
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 10,
  //     "itens": [
  //       {
  //         "link": "#ededed",
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/1-11.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/1-11.webp",
  //               "altText": ""
  //             },
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/2-10.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/2-10.webp",
  //               "altText": ""
  //             },
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/3-10.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/3-10.webp",
  //               "altText": ""
  //             },
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/4-8.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/4-8.webp",
  //               "altText": ""
  //             },
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/5-5.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/5-5.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": "VÍDEO DE PRODUTO",
  //     "number": 18,
  //     "itens": [
  //       {
  //         "link": "https://www.youtube.com/embed/Kg7gF3L-LCE",
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-anelar.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-anelar.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 18,
  //     "itens": [
  //       {
  //         "link": "https://www.youtube.com/embed/ayeaXHP5tlc",
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-turboram-XL.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-turboram-XL.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 18,
  //     "itens": [
  //       {
  //         "link": "https://www.youtube.com/embed/1K1br55Tlt4",
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-auto-lavagem.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-auto-lavagem.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": "VÍDEO EMBAIXADOR DA MARCA",
  //     "number": 18,
  //     "itens": [
  //       {
  //         "link": "https://www.youtube.com/embed/mTNGdZ7unME",
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-embaixador.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-embaixador.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 9,
  //     "itens": null,
  //     "cols": [
  //       {
  //         "text": "VÍDEO INSTITUCIONAL",
  //         "titleandtext": {
  //           "text": "Com ponto de partida em 1978, o vídeo institucional da Ramalhos é reflexo de uma passagem de testemunho entre avô e neto. Numa viagem entre passado e presente, a narrativa revela como a paixão pela empresa e respetiva missão se mantêm vivas. <br/>Ao longo dos quase 3 minutos de vídeo, o espectador é convidado a entrar no universo da Ramalhos e a descobrir a essência da marca.",
  //           "title": "Uma dedicação que se sente, fornada após fornada"
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 18,
  //     "itens": [
  //       {
  //         "link": "https://www.youtube.com/embed/RaAmWPaRh7o",
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-institucional.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-institucional.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 15,
  //     "itens": [
  //       {
  //         "link": null,
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/1-10.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/1-10.webp",
  //               "altText": ""
  //             },
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/2-9.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/2-9.webp",
  //               "altText": ""
  //             },
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/3-9.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/3-9.webp",
  //               "altText": ""
  //             },
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/4-7.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/4-7.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": [
  //       {
  //         "text": "EDITORIAL",
  //         "titleandtext": {
  //           "text": "Da fábrica para as mãos do cliente, os catálogos comerciais e técnicos são duas importantes ferramentas de apoio. <br/>Nestas páginas é possível escolher o forno certo. Das vantagens às várias características e funcionalidades, nos catálogos estão todas as opções de personalização que a Ramalhos oferece, bem como as instruções para uma correta instalação.",
  //           "title": "Catálogo comercial e técnico"
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "anchor": null,
  //     "classname": "container-margin pe-0 ps-0",
  //     "title": "CONTEÚDO REDES SOCIAIS",
  //     "number": 4,
  //     "itens": [
  //       {
  //         "link": null,
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/redes-sociais-1.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/redes-sociais-1.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": [
  //       {
  //         "text": null,
  //         "titleandtext": {
  //           "text": null,
  //           "title": null
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 9,
  //     "itens": null,
  //     "cols": [
  //       {
  //         "text": "CAMPANA NATAL 2021",
  //         "titleandtext": {
  //           "text": "“Who baked the Gingerbread man?” é o mote da campanha de Natal 2021 da Ramalhos. <br/>Um dos conteúdos é um curto vídeo que reinterpreta a tradição de deixar comida ao Pai Natal. Provenientes dos fornos da empresa, os biscoitos com formas natalícias revelam-se irresistíveis. <br/>À tradição, juntam-se várias referências à marca, numa mensagem descontraída e alegre, criada para assinalar o Natal.",
  //           "title": "Who baked the Gingerbread man?"
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 18,
  //     "itens": [
  //       {
  //         "link": "https://www.youtube.com/embed/Ig7IZFh-aa8",
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-natal-21.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/thumbnail-natal-21.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 9,
  //     "itens": null,
  //     "cols": [
  //       {
  //         "text": "CONFIGURADOR",
  //         "titleandtext": {
  //           "text": "Obter um forno à medida das necessidades é possível com o Configurador online da Ramalhos. A pensar nos seus diferentes clientes, a marca tem à disposição uma página web que permite personalizar cada encomenda. <br/>Disponível para todas as gamas, este Configurador online permite selecionar características pretendidas para cada forno, num exercício que dá ao cliente toda a liberdade de escolha. <br/>Dos modelos à largura das portas, as opções de seleção são muitas. Um resultado final que reflete o trabalho prévio de programação e implementação.",
  //           "title": "Um forno para cada negócio"
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "anchor": null,
  //     "classname": null,
  //     "title": null,
  //     "number": 19,
  //     "itens": [
  //       {
  //         "link": "https://ramalhos.com/",
  //         "imagesrc": {
  //           "nodes": [
  //             {
  //               "sourceUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/website-4.webp",
  //               "mediaItemUrl": "https://news.invisual.pt/wp/wp-content/uploads/2024/11/website-4.webp",
  //               "altText": ""
  //             }
  //           ]
  //         }
  //       }
  //     ],
  //     "cols": null
  //   }
  // ]
  return (
    <Layout mobile={mobile} post={null} data={t("global", { returnObjects: true })} page={t("portfolio", { returnObjects: true }).page} seo={seo} location={location}>
      {mobile ?
        <>
          <MenuGlobal noFilter location={location} portfolioIndividual
            global={t("global", { returnObjects: true })} centered={true} mobile noScroll />

          <SmoothScroll noTopSpace>

            <div className="container-scroll-change-bg" style={{ background: 'linear-gradient(180deg, #EDEDED 0%, #F8F4EF 100%)' }}>
              {pageContext.project.projetos.blocks
                .map((block, index) => {
                  switch (block.number) {
                    case 2:
                      return (
                        <Suspense >
                          <SmallHeightMarginWidth data={block} mobile />
                        </Suspense>
                      )
                    case 3:
                      return (
                        <Suspense >
                          <SmallHeightfullWidth data={block} />
                        </Suspense>
                      )
                    case 4:
                      return (
                        <Suspense >
                          <FullWidthImage data={block} />
                        </Suspense>
                      )
                    case 8:
                    default:
                      return (
                        <TopParallax data={block} />
                      )
                    case 9:
                      return (
                        <Suspense >
                          <AreasTitleText data={block} mobile />
                        </Suspense>
                      )
                    case 10:
                      return (
                        <Suspense >
                          <SliderWithBackgroundImageWithColor data={block} />
                        </Suspense>
                      )
                    case 11:
                      return (
                        <Suspense >
                          <FullWidthImageZoom data={block} />
                        </Suspense>
                      )
                    case 12:
                      return (
                        <Suspense >
                          <SameHeightTitleText data={block} />
                        </Suspense>
                      )
                    case 13:
                      return (
                        <Suspense >
                          <TwoImagesDifferentsSpeedsMobile data={block} />
                        </Suspense>
                      )
                    case 14:
                      return (
                        <Suspense >
                          <SliderCardsHorizontalWithDrag data={block} mobile />
                        </Suspense>
                      )
                    case 15:
                      return (
                        <Suspense >
                          <TextLeftSliderRightWithOneAndHalfSlidesMobile data={block} />
                        </Suspense>
                      )
                    case 16:
                      return (
                        <Suspense >
                          <VideoInViewPlayAndPauseWithBorderRadius data={block} />
                        </Suspense>
                      )
                    case 17:
                      return (
                        <Suspense >
                          <TextLeftSliderRightWithOneAndHalfSlidesMobile data={block} video />
                        </Suspense>
                      )
                    case 18:
                      return (
                        <Suspense >
                          <ImageLinkYoutube data={block} mobile />
                        </Suspense>
                      )
                    case 19:
                      return (
                        <Suspense >
                          <MarginWidthMockupWebsiteWithButton data={block} mobile btn={t("portfolio", { returnObjects: true }).websiteBtn} />
                        </Suspense>
                      )
                  }
                })}
            </div>


          </SmoothScroll>

        </>
        :
        <>
          <MenuGlobal noFilter location={location} portfolioIndividual
            global={t("global", { returnObjects: true })} centered={true} noScroll>

            <SmoothScroll noTopSpace>

              <div className="container-scroll-change-bg" style={{ background: 'linear-gradient(180deg, #EDEDED 0%, #F8F4EF 100%)' }}>

                {pageContext.project.projetos.blocks
                  .map((block, index) => {
                    switch (block.number) {
                      case 2:
                        return (
                          <Suspense >
                            <SmallHeightMarginWidth data={block} />
                          </Suspense>
                        )
                      case 3:
                        return (
                          <Suspense >
                            <SmallHeightfullWidth data={block} />
                          </Suspense>
                        )
                      case 4:
                        return (
                          <Suspense >
                            <FullWidthImage data={block} />
                          </Suspense>
                        )
                      case 8:
                      default:
                        return (
                          <TopParallax data={block} />
                        )
                      case 9:
                        return (
                          <Suspense >
                            <AreasTitleText data={block} />
                          </Suspense>
                        )
                      case 10:
                        return (
                          <Suspense >
                            <SliderWithBackgroundImageWithColor data={block} />
                          </Suspense>
                        )
                      case 11:
                        return (
                          <Suspense >
                            <FullWidthImageZoom data={block} />
                          </Suspense>
                        )
                      case 12:
                        return (
                          <Suspense >
                            <SameHeightTitleText data={block} />
                          </Suspense>
                        )
                      case 13:
                        return (
                          <Suspense >
                            <TwoImagesDifferentsSpeeds data={block} />
                          </Suspense>
                        )
                      case 14:
                        return (
                          <Suspense >
                            <SliderCardsHorizontalWithDrag data={block} />
                          </Suspense>
                        )
                      case 15:
                        return (
                          <Suspense >
                            <TextLeftSliderRightWithOneAndHalfSlides data={block} />
                          </Suspense>
                        )
                      case 16:
                        return (
                          <Suspense >
                            <VideoInViewPlayAndPauseWithBorderRadius data={block} />
                          </Suspense>
                        )
                      case 17:
                        return (
                          <Suspense >
                            <TextLeftSliderRightWithOneAndHalfSlides data={block} video />
                          </Suspense>
                        )
                      case 18:
                        return (
                          <Suspense >
                            <ImageLinkYoutube data={block} />
                          </Suspense>
                        )
                      case 19:
                        return (
                          <Suspense >
                            <MarginWidthMockupWebsiteWithButton data={block} btn={t("portfolio", { returnObjects: true }).websiteBtn} />
                          </Suspense>
                        )
                    }
                  })}

                {/**<Suspense >
                  <FullscreenSlider />
                </Suspense>*/}
              </div>

            </SmoothScroll>
          </MenuGlobal>
        </>
      }


    </Layout>
  )
}



export default PortfolioIndividualTemplate


export const pageQuery = graphql`
    query($language: String!) {
      locales: allLocale(filter: {ns: {in: ["global", "portfolio"]}, language: {eq: $language}}) {
      edges {
      node {
      ns
          data
          language
        }
      }
    }
    allFile {
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`