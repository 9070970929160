import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const TopParallax = ({ data }) => {
    const imageRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const image = imageRef.current;

        if (image) {
            ScrollTrigger.create({
                trigger: image,
                start: "top top",
                end: "+=50%",
                toggleActions: "play none reverse play",
                scrub: true,
                overscroll: true,
                onUpdate: (scrollTrigger) => {
                    // Update y and scale based on scroll position
                    gsap.to(image, {
                        y: scrollTrigger.progress * 50, // Adjust max scroll offset
                        scale: 1 + scrollTrigger.progress * 0.2, // Adjust max zoom factor
                        ease: "power2.out",
                    });
                },
            });
        }
    }, [imageRef]);

    return (
        <div className="container-top-section-top-parallax2" >
            {data.itens.length > 1 &&
                <div className="container-top-parallax-logo">
                    <img src={data.itens[1]?.imagesrc.nodes[0].sourceUrl} className="logo" alt="logo" />
                </div>
            }
            {data.cols &&
                <div className="container-top-text">
                    <h1 className="fs-1 ILightBeta mb-4 white" dangerouslySetInnerHTML={{ __html: data.cols[0].titleandtext.title }} />
                </div>
            }
            <div className="top-parallax" ref={imageRef}>
                <img alt="parallax-top"
                    src={data.itens[0]?.imagesrc.nodes[0].sourceUrl}
                    className="parallax-image"
                    ref={imageRef}
                />
            </div>
        </div>
    );
};

export default TopParallax;